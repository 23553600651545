<template>
	<div class="profile-history">
		<HistoryTable
			class="history-wrap"
			:header="[
				t('Profile.operation'),
				'',
				t('Profile.sum'),
				'',
				t('Profile.method'),
				'',
				t('Profile.bonus'),
				'',
				t('Profile.date')
			]"
			:columns="['', '', '', '', '', '', '']"
			:count="transactions.length"
			v-if="!loading"
		>
			<Transaction
				class="item-wrap"
				v-for="tx in transactions"
				:key="`${tx.type}-${tx.id}`"
				:id="tx.id"
				:type="tx.type"
				:amount="tx.amountUsd"
				:bonusAmount="tx.bonusAmountUsd"
				:bonusPercent="tx.bonusPercent"
				:method="tx.method"
				:item="tx.item"
				:date="new Date(tx.updatedAt)"
			/>
		</HistoryTable>
		<Spinner width="64px" height="64px" class="spinner-wrap" v-if="loading" />
	</div>
</template>
<script lang="ts">
import { useAPI } from '@/composables/api'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import HistoryTable from '../../components/HistoryTable/index.vue'
import Transaction from '../../components/HistoryTable/Transaction.vue'
import Spinner from '../../components/Spinner/index.vue'

export default defineComponent({
	components: {
		Spinner,
		HistoryTable,
		Transaction
	},
	setup() {
		const { t } = useI18n()
		const api = useAPI()

		const state = reactive({
			transactions: [] as any[],
			loading: true
		})

		const getHistory = async () => {
			try {
				const res = await api({ path: '/users/current/history' })

				state.transactions = []

				for (let i = 0; i < res.paymentsHistory.length; i++) {
					state.transactions.push({ type: 'DEPOSIT', ...res.paymentsHistory[i] })
				}

				for (let i = 0; i < res.withdrawalsHistory.length; i++) {
					state.transactions.push({
						type: 'WITHDRAW',
						...res.withdrawalsHistory[i],
						amountUsd: res.withdrawalsHistory[i].item.priceUsd
					})
				}

				state.transactions.sort((a, b) => {
					if (new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime())
						return -1
					if (new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime())
						return 1
					return 0
				})
			} catch (err) {
				// empty
			}

			state.loading = false
		}

		onMounted(() => {
			getHistory()
		})

		return {
			t,
			...toRefs(state)
		}
	}
})
</script>
<style scoped>
.profile-history {
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 10px;
	margin-right: -10px;
}

.spinner-wrap {
	margin: auto;
	color: rgba(175, 177, 192, 0.5);
}

.history-wrap {
	flex: 1;
	width: 100%;
}
</style>
