
import ItemIcon from '../ItemIcon/index.vue'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useNormalizeDate } from '@/composables/useNormalizeDate'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		amount: {
			type: Number,
			required: true
		},
		bonusAmount: {
			type: Number
		},
		bonusPercent: {
			type: Number
		},
		item: {
			type: Object
		},
		type: {
			type: Object as PropType<'DEPOSIT' | 'WITHDRAW'>,
			required: true
		},

		// define a type !!!!!
		method: {
			type: Object
		},
		
		date: {
			type: Object as PropType<Date>,
			required: true
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const { t } = useI18n()
		const { normalizeDate } = useNormalizeDate()

		const idNormalized = computed(() => {
			return `#${props.id}`
		})

		const operation = computed(() => {
			return `${
				props.type === 'DEPOSIT'
					? t('HistoryTable.deposit')
					: t('HistoryTable.withdraw')
			} #${props.id}`
		})

		const amountNormalized = computed(() => {
			return `$${props.amount / 100}`
		})

		const methodIcon = computed(() => {
			if (props.type === 'WITHDRAW') {
				return require(`../../assets/icons/methods/steam.svg`)
			}
			if (!props.method) {
				return ''
			}

			return require(`../../assets/icons/methods/${props.method.group ? props.method.group : props.method.name.replace(/enot_/g, '')}.svg`)
		})

		const methodNormalized = computed(() => {
			if (props.type === 'WITHDRAW') {
				return t('HistoryTable.steam')
			}
			if (!props.method) {
				return ''
			}

			return (props.method.displayName ? props.method.displayName : (props.method.group ? props.method.group : props.method.name)).replace('blocked', 'adv').replace(/enot_/g, '').replace(/_/g, ' ')
		})

		const bonusNormalized = computed(() => {
			if (!props.bonusAmount || !props.bonusPercent) {
				return ''
			}

			return `$${props.bonusAmount / 100} (+${props.bonusPercent}%)`
		})

		const dateNormalized = computed(() => {
			if (!props.date) {
				return ''
			}

			return normalizeDate(props.date)
		})

		return {
			t,
			idNormalized,
			operation,
			amountNormalized,
			methodIcon,
			methodNormalized,
			bonusNormalized,
			dateNormalized
		}
	}
})
