<template>
	<tr class="crash-item">
		<td class="item-operation">
			<div class="tr-left">{{ t('Profile.operation') }}</div>
			<div class="tr-right center" :class="[type.toLowerCase()]">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					class="operation-icon"
					v-if="type === 'DEPOSIT'"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M18 20H6C4.895 20 4 19.105 4 18V6C4 4.895 4.895 4 6 4H18C19.105 4 20 4.895 20 6V18C20 19.105 19.105 20 18 20Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 8V16"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M16 12H8"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					class="operation-icon"
					v-if="type === 'WITHDRAW'"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M18 20H6C4.895 20 4 19.105 4 18V6C4 4.895 4.895 4 6 4H18C19.105 4 20 4.895 20 6V18C20 19.105 19.105 20 18 20Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M16 12H8"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div class="operation-text">{{ operation }}</div>
			</div>
		</td>
		<td class="item-divider table"><div class="divider" /></td>
		<td class="item-amount">
			<div class="tr-left">{{ t('Profile.sum') }}</div>
			<div class="tr-right center">
				<ItemIcon
					v-if="type === 'WITHDRAW' && item"
					:icon="item.icon"
					class="item-icon-wrap"
					:size="80"
				/>
				<div class="amount-text">{{ amountNormalized }}</div>
			</div>
		</td>
		<td class="item-divider table"><div class="divider" /></td>
		<td class="item-method">
			<div class="tr-left">{{ t('Profile.method') }}</div>
			<div class="tr-right center">
				<img :src="methodIcon" class="method-icon" />
				<div class="method-text">
					{{ methodNormalized }}
				</div>
			</div>
		</td>
		<td class="item-divider table"><div class="divider" /></td>
		<td class="item-bonus">
			<div class="tr-left">{{ t('Profile.bonus') }}</div>
			<div class="tr-right center" v-if="bonusAmount">
				{{ bonusNormalized }}
			</div>
		</td>
		<td class="item-divider table"><div class="divider" /></td>
		<td class="item-date">
			<div class="tr-left">{{ t('Profile.date') }}</div>
			<div class="tr-right center">{{ dateNormalized }}</div>
		</td>
	</tr>
</template>
<script lang="ts">
import ItemIcon from '../ItemIcon/index.vue'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useNormalizeDate } from '@/composables/useNormalizeDate'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		amount: {
			type: Number,
			required: true
		},
		bonusAmount: {
			type: Number
		},
		bonusPercent: {
			type: Number
		},
		item: {
			type: Object
		},
		type: {
			type: Object as PropType<'DEPOSIT' | 'WITHDRAW'>,
			required: true
		},

		// define a type !!!!!
		method: {
			type: Object
		},
		
		date: {
			type: Object as PropType<Date>,
			required: true
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const { t } = useI18n()
		const { normalizeDate } = useNormalizeDate()

		const idNormalized = computed(() => {
			return `#${props.id}`
		})

		const operation = computed(() => {
			return `${
				props.type === 'DEPOSIT'
					? t('HistoryTable.deposit')
					: t('HistoryTable.withdraw')
			} #${props.id}`
		})

		const amountNormalized = computed(() => {
			return `$${props.amount / 100}`
		})

		const methodIcon = computed(() => {
			if (props.type === 'WITHDRAW') {
				return require(`../../assets/icons/methods/steam.svg`)
			}
			if (!props.method) {
				return ''
			}

			return require(`../../assets/icons/methods/${props.method.group ? props.method.group : props.method.name.replace(/enot_/g, '')}.svg`)
		})

		const methodNormalized = computed(() => {
			if (props.type === 'WITHDRAW') {
				return t('HistoryTable.steam')
			}
			if (!props.method) {
				return ''
			}

			return (props.method.displayName ? props.method.displayName : (props.method.group ? props.method.group : props.method.name)).replace('blocked', 'adv').replace(/enot_/g, '').replace(/_/g, ' ')
		})

		const bonusNormalized = computed(() => {
			if (!props.bonusAmount || !props.bonusPercent) {
				return ''
			}

			return `$${props.bonusAmount / 100} (+${props.bonusPercent}%)`
		})

		const dateNormalized = computed(() => {
			if (!props.date) {
				return ''
			}

			return normalizeDate(props.date)
		})

		return {
			t,
			idNormalized,
			operation,
			amountNormalized,
			methodIcon,
			methodNormalized,
			bonusNormalized,
			dateNormalized
		}
	}
})
</script>
<style scoped>
.divider {
	background: #a2a3ac;
	opacity: 0.1;
	border-radius: 20px;
	height: 30px;
	width: 1px;
	margin: auto;
}

.item-operation {
	padding: 0 19px;
}

.operation-icon {
	margin: auto 0;
}

.item-operation .tr-right.deposit {
	color: #97ed4c;
}

.item-operation .tr-right.withdraw {
	color: #ff6339;
}

.operation-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	margin: auto 0 auto 10px;
}

.xs .item-id {
	font-size: 14px;
	line-height: 18px;
}

.item-amount,
.item-method,
.item-bonus {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	padding: 0 10px;
}

.method-icon {
	height: 24px;
	width: 24px;
	margin: auto 0;
}

.method-text {
	margin: auto 0 auto 10px;
	text-transform: capitalize;
}

.item-icon-wrap {
	height: 40px;
	width: 40px;
	margin-right: 10px;
}

.xs .item-icon-wrap {
	height: 30px;
	width: 30px;
}

.amount-text {
	margin: auto 0;
}

.item-date {
	font-size: 13px;
	line-height: 17px;
	color: #a2a3ac;
	padding: 0 10px;
	text-align: center;
}

.xs .item-date {
	font-size: 14px;
}
</style>
