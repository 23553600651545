<template>
	<div
		class="history-table"
		v-responsive="{
			xs: (el) => el.width < 600,
			sm: (el) => el.width < 700,
			md: (el) => el.width < 800
		}"
	>
		<div class="table-bg">
			<div class="bg-item head" />
			<div class="bg-item" v-for="i in count" :key="`bg-${i}`" />
		</div>
		<table>
			<colgroup>
				<col
					v-for="(column, i) in columns"
					:key="`c-${i}`"
					:style="{ width: `${column}` }"
				/>
			</colgroup>
			<thead>
				<tr>
					<th v-for="(item, i) in header" :key="`h-${i}`" :class="[columns[i]]">
						{{ item }}
					</th>
				</tr>
			</thead>
			<tbody>
				<slot />
			</tbody>
		</table>
		<div class="items-placeholder" v-if="!count">
			{{ t('HistoryTable.placeholder') }}
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { ResponsiveDirective as responsive } from 'vue-responsive-components'

export default defineComponent({
	props: {
		header: {
			type: Array,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		count: {
			type: Number,
			required: true
		}
	},
	directives: {
		responsive
	},
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
.history-table {
	position: relative;
	z-index: 0;
}

table {
	z-index: 1;
	width: 100%;
	position: relative;
	margin-top: -5px;
	border-spacing: 0 5px;
	padding-bottom: 40px;
}

.xs table {
	display: flex;
	flex-direction: column;
	margin-top: unset;
}

.xs thead,
.xs .table-bg {
	display: none;
}

.xs colgroup {
	display: none;
}

td,
.item-wrap ::v-deep td {
	border: none;
}

tr,
.item-wrap ::v-deep tr {
	height: 60px;
	box-sizing: border-box;
}

.item-wrap ::v-deep .tr-left {
	display: none;
}

.xs .item-wrap ::v-deep .tr-left {
	display: unset;
}

.item-wrap ::v-deep .tr-right {
	display: flex;
	width: 100%;
}

.item-wrap ::v-deep .tr-right.center {
	justify-content: center;
	text-align: center;
}

th,
.item-wrap ::v-deep .tr-left {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
	color: rgba(162, 163, 172, 0.5);
}

.item-wrap ::v-deep td {
	border: none;
}

.xs tr,
.xs .item-wrap ::v-deep tr {
	display: flex;
	flex-direction: column;
	height: unset;
	background: #2b2e39;
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 0 20px;
}

.xs .item-wrap ::v-deep td {
	display: flex;
	justify-content: space-between;
	padding: 0;
	height: 58px;
	align-items: center;
	border-bottom: 1px solid rgba(162, 163, 172, 0.1);
}

.xs .item-wrap ::v-deep td:last-of-type {
	border-bottom: unset;
}

.xs .item-wrap ::v-deep .table {
	display: none;
}

.history-table:not(.xs) .item-wrap ::v-deep .card {
	display: none;
}

.xs .item-wrap ::v-deep .tr-right {
	margin-left: auto;
	justify-content: unset !important;
	width: fit-content;
}

.md .md,
.sm .sm,
.xs .xs {
	display: none;
}

.items-placeholder {
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	margin: auto;
	text-align: center;
	color: rgba(175, 177, 192, 0.5);
}

.table-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}

.bg-item {
	background: #2b2e39;
	border-radius: 10px;
	margin-bottom: 5px;
	height: 60px;
}
</style>
