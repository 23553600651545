
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { ResponsiveDirective as responsive } from 'vue-responsive-components'

export default defineComponent({
	props: {
		header: {
			type: Array,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		count: {
			type: Number,
			required: true
		}
	},
	directives: {
		responsive
	},
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
