
import { useAPI } from '@/composables/api'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import HistoryTable from '../../components/HistoryTable/index.vue'
import Transaction from '../../components/HistoryTable/Transaction.vue'
import Spinner from '../../components/Spinner/index.vue'

export default defineComponent({
	components: {
		Spinner,
		HistoryTable,
		Transaction
	},
	setup() {
		const { t } = useI18n()
		const api = useAPI()

		const state = reactive({
			transactions: [] as any[],
			loading: true
		})

		const getHistory = async () => {
			try {
				const res = await api({ path: '/users/current/history' })

				state.transactions = []

				for (let i = 0; i < res.paymentsHistory.length; i++) {
					state.transactions.push({ type: 'DEPOSIT', ...res.paymentsHistory[i] })
				}

				for (let i = 0; i < res.withdrawalsHistory.length; i++) {
					state.transactions.push({
						type: 'WITHDRAW',
						...res.withdrawalsHistory[i],
						amountUsd: res.withdrawalsHistory[i].item.priceUsd
					})
				}

				state.transactions.sort((a, b) => {
					if (new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime())
						return -1
					if (new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime())
						return 1
					return 0
				})
			} catch (err) {
				// empty
			}

			state.loading = false
		}

		onMounted(() => {
			getHistory()
		})

		return {
			t,
			...toRefs(state)
		}
	}
})
